import * as React from "react";
import { IJobListItemsProps } from "../Interfaces/props";
import {
  ChevronRightIcon,
  MapPinIcon,
  BriefcaseIcon,
  CalendarIcon,
  GlobeAltIcon,
} from "@heroicons/react/20/solid";
import { ReactElement } from "react";

function JobListItems({ job, onClick }: IJobListItemsProps): ReactElement {
  const APPLICATION_DEADLINE: string = job.ApplicationDeadline
    ? new Date(job.ApplicationDeadline).toLocaleDateString("de-DE")
    : "Keine Frist";

  let language: string;
  if (job.Language === "en") {
    language = "English";
  } else language = "Deutsch";

  return (
    <li
      onClick={onClick}
      className="group p-6 hover:bg-hellgelb/5 cursor-pointer border-b border-gray-100 transition-all duration-200"
    >
      <div className="flex justify-between items-start gap-4">
        <div className="flex-1 space-y-3">
          <div>
            <h3 className="text-xl font-semibold text-dunkelblau group-hover:text-orange transition-colors">
              {job.Title}
            </h3>
            <p className="text-base text-gray-600 mt-1">{job.Employer.Name}</p>
          </div>

          <div className="flex flex-wrap gap-4 text-sm text-gray-600">
            <div className="flex items-center gap-1.5">
              <MapPinIcon className="h-4 w-4 text-gray-400" />
              <span>{job.Location.City}</span>
            </div>
            <div className="flex items-center gap-1.5">
              <BriefcaseIcon className="h-4 w-4 text-gray-400" />
              <span>{job.Employer.Name}</span>
            </div>
            <div className="flex items-center gap-1.5">
              <CalendarIcon className="h-4 w-4 text-gray-400" />
              <span>Bewerbungsfrist: {APPLICATION_DEADLINE}</span>
            </div>
            <div className="flex items-center gap-1.5">
              <GlobeAltIcon className="h-4 w-4 text-gray-400" />
              <span>{language}</span>
            </div>
          </div>
        </div>

        <ChevronRightIcon
          className="h-6 w-6 text-gray-400 group-hover:text-orange transition-colors"
          aria-hidden="true"
        />
      </div>
    </li>
  );
}

export default JobListItems;
