import { fetchJobs } from "../apiReceive/receiveJob";
import { IJob } from "../Interfaces/types";

export async function getJobs(): Promise<IJob[]> {
  const JOB: IJob[] = await fetchJobs();
  return JOB;
}

export async function getJobTitles(): Promise<string[]> {
  const DATA: IJob[] = await getJobs();
  const JOB_TITLES: string[] = DATA.map(function (job: IJob) {
    return job.Title;
  });
  return JOB_TITLES;
}

export async function sortJobs(order: string) {
  let jobs: IJob[] = await getJobs();
  let sortedJobs: IJob[] = [...jobs].sort(function (leftJob: IJob, rightJob: IJob) {
    const LEFT_JOB_TITLE: string = leftJob.Title.toUpperCase();
    const RIGHT_JOB_TITLE: string = rightJob.Title.toUpperCase();
    if (LEFT_JOB_TITLE <= RIGHT_JOB_TITLE) return order === "asc" ? -1 : 1;
    if (LEFT_JOB_TITLE > RIGHT_JOB_TITLE) return order === "asc" ? 1 : -1;
  });
  return { sortedJobs, sortOrder: order };
}

export async function toggleSortOrder(currentOrder: string) {
  let newOrder: string = currentOrder === "asc" ? "desc" : "asc";
  let { sortedJobs } = await sortJobs(newOrder);
  return { sortedJobs, newOrder };
}
