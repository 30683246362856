import * as React from "react";
import { useState, useEffect, ReactElement } from "react";
import Frame from "./Dialog";
import { getJobs, toggleSortOrder } from "../Clientlogic/Job";
import JobListItems from "./JobListItems";
import { IJob } from "../Interfaces/types";
import SearchBar from "./SearchBar";

function JobListFrame(): ReactElement {
  const [JOBS, SET_JOBS]: [IJob[], React.Dispatch<React.SetStateAction<IJob[]>>] = useState<IJob[]>(
    [],
  );
  const [SELECTED_JOB, SET_SELECTED_JOB]: [
    IJob | null,
    React.Dispatch<React.SetStateAction<IJob | null>>,
  ] = useState<IJob | null>(null);

  useEffect(function (): void {
    async function fetchData(): Promise<void> {
      const JOBS: IJob[] = await getJobs();
      SET_JOBS(JOBS);
    }
    fetchData();
  }, []);

  function openDialog(job: IJob): void {
    SET_SELECTED_JOB(job);
  }

  function closeDialog(): void {
    SET_SELECTED_JOB(null);
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div
        className="w-[85%] max-w-6xl mx-auto ml-[15%] mr-[5%] sm:mr-[8%] lg:mr-[10%] 
                      px-6 sm:px-8 lg:px-12 py-8 font-sans"
      >
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-dunkelblau">Job-Anzeigen</h1>
        </div>

        <div className="mb-8">
          <SearchBar
            onSort={async function (ORDER: string): Promise<void> {
              const { sortedJobs: SORTED_JOBS }: { sortedJobs: IJob[]; newOrder: string } =
                await toggleSortOrder(ORDER);
              SET_JOBS(SORTED_JOBS);
            }}
          />
        </div>

        <div className="mt-8 bg-white rounded-xl shadow-lg border border-gray-200 overflow-hidden">
          <div className="p-5 border-b border-gray-200 flex justify-between items-center bg-gray-50">
            <div className="flex items-center gap-2">
              <span className="text-sm text-gray-600 font-medium">
                {JOBS.length} Stellen gefunden
              </span>
            </div>
          </div>

          <ul className="divide-y divide-gray-100">
            {JOBS.map(function (JOB: IJob): ReactElement {
              return React.createElement(JobListItems, {
                job: JOB,
                onClick: function (): void {
                  openDialog(JOB);
                },
                key: JOB.JobID,
              });
            })}
          </ul>

          {JOBS.length === 0 && (
            <div className="p-8 text-center text-gray-500">
              <p>Keine Jobs gefunden</p>
            </div>
          )}
        </div>
      </div>

      <Frame isOpen={!!SELECTED_JOB} job={SELECTED_JOB} onClose={closeDialog} />
    </div>
  );
}

export default JobListFrame;
