import axios, { AxiosResponse } from "axios";
import { IJob } from "../Interfaces/types";

const URL: string = "http://localhost:4010/api/jobs";

/**
 * Fetches jobs from the specified API endpoint.
 *
 * This function sends a GET request to the specified URL to retrieve a list of jobs.
 * If the request is successful, it returns the response data. If the request fails,
 * it handles different types of errors and alerts the user if the API URL cannot be reached.
 *
 * @returns {Promise<any>} A promise that resolves to the response data from the API if the request is successful.
 *
 * @throws {Error} Throws an error if the request fails.
 *
 *
 * Error Handling:
 * - If the request was made but no response was received (e.g., the API URL is unreachable),
 *   an alert is shown to the user.
 * - All errors are logged to the console with a message indicating that there was an error
 *   fetching the jobs.
 */
export async function fetchJobs(): Promise<IJob[]> {
  try {
    const RESPONSE: AxiosResponse = await axios.get<IJob[]>(URL);
    const RESULT: IJob[] = RESPONSE.data;
    return RESULT;
  } catch (error) {
    alert("Could not receive Jobs from Database!");
    console.error("There was an error fetching the jobs", error);
    throw error;
  }
}

export default fetchJobs;
