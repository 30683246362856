import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const ROOT: ReactDOM.Root = ReactDOM.createRoot(document.getElementById("root"));
ROOT.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

reportWebVitals(console.log);
