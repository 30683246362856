import * as React from "react";
import JobList from "./components/JobListFrame";

function App() {
  return (
    <div className="app">
      <JobList />
    </div>
  );
}

export default App;
