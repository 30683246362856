import * as React from "react";
import { IDialogProps } from "../Interfaces/props";
import type { ReactElement } from "react";
import {
  MapPinIcon,
  PhoneIcon,
  EnvelopeIcon,
  GlobeAltIcon,
  BuildingOfficeIcon,
} from "@heroicons/react/20/solid";

function Contact({ job }: IDialogProps): ReactElement {
  return (
    <div className="p-4 space-y-4">
      <div className="h-64 w-full mb-4">
        <div
          className="bg-gradient-to-br from-hellblau/5 to-hellblau/10 h-full w-full 
                      rounded-xl shadow-lg flex items-center justify-center 
                      border border-hellblau/20"
        >
          <MapPinIcon className="h-12 w-12 text-dunkelblau" />
        </div>
      </div>

      <div className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden">
        <div className="p-6 space-y-6">
          <h2 className="text-xl font-bold text-dunkelblau">Kontaktinformationen</h2>

          <div className="space-y-4">
            <div className="flex items-start gap-4">
              <BuildingOfficeIcon className="h-5 w-5 text-dunkelblau flex-shrink-0 mt-0.5" />
              <div>
                <p className="font-medium text-dunkelblau">{job.Employer.Name}</p>
              </div>
            </div>

            <div className="flex items-center gap-4">
              <PhoneIcon className="h-5 w-5 text-dunkelblau flex-shrink-0" />
              <p className="text-dunkelblau">+49 123 4567890</p>
            </div>

            <div className="flex items-center gap-4">
              <EnvelopeIcon className="h-5 w-5 text-dunkelblau flex-shrink-0" />
              <p className="text-dunkelblau">{job.Employer.Email}</p>
            </div>

            <div className="flex items-center gap-4">
              <GlobeAltIcon className="h-5 w-5 text-dunkelblau flex-shrink-0" />
              <a
                href={job.Website.JobURL}
                className="text-blue-600 hover:text-blue-800 transition-colors hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Zur Stellenanzeige
              </a>
            </div>

            <div className="flex items-start gap-4">
              <MapPinIcon className="h-5 w-5 text-dunkelblau flex-shrink-0 mt-0.5" />
              <div className="text-dunkelblau">
                <p>
                  {job.Location.Street} {job.Location.Housenumber}
                </p>
                <p>
                  {job.Location.PostalCode} {job.Location.City}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
