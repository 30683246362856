import * as React from "react";
import { useState, useRef, ChangeEvent, ReactElement } from "react";
import { XMarkIcon, FunnelIcon, MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { ISearchBarProps } from "../Interfaces/props";
import { IFilter } from "../Interfaces/types";

function SearchBar({ onSort }: ISearchBarProps): ReactElement {
  const [IS_FILTER_OPEN, SET_IS_FILTER_OPEN]: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>,
  ] = useState<boolean>(false);
  const [ACTIVE_FILTERS, SET_ACTIVE_FILTERS]: [
    IFilter[],
    React.Dispatch<React.SetStateAction<IFilter[]>>,
  ] = useState<IFilter[]>([]);
  const [SEARCH_TERM, SET_SEARCH_TERM]: [string, React.Dispatch<React.SetStateAction<string>>] =
    useState<string>("");

  const LANGUAGE_REF: React.RefObject<HTMLSelectElement> = useRef<HTMLSelectElement>(null);
  const DISTANCE_REF: React.RefObject<HTMLSelectElement> = useRef<HTMLSelectElement>(null);
  const FIELD_REF: React.RefObject<HTMLSelectElement> = useRef<HTMLSelectElement>(null);
  const SORT_REF: React.RefObject<HTMLSelectElement> = useRef<HTMLSelectElement>(null);

  function FILTER_BY_ID(f: IFilter, id: string): boolean {
    return f.id !== id;
  }

  function UPDATE_FILTER(filter: IFilter): void {
    const OTHER_FILTERS: IFilter[] = ACTIVE_FILTERS.filter(function (f: IFilter): boolean {
      return FILTER_BY_ID(f, filter.id);
    });
    if (filter.value) {
      SET_ACTIVE_FILTERS([...OTHER_FILTERS, filter]);
    } else {
      SET_ACTIVE_FILTERS(OTHER_FILTERS);
    }
  }

  function REMOVE_FILTER(filterId: string): void {
    SET_ACTIVE_FILTERS(
      ACTIVE_FILTERS.filter(function (filter: IFilter): boolean {
        return FILTER_BY_ID(filter, filterId);
      }),
    );

    switch (filterId) {
      case "language":
        if (LANGUAGE_REF.current) LANGUAGE_REF.current.value = "";
        break;
      case "distance":
        if (DISTANCE_REF.current) DISTANCE_REF.current.value = "";
        break;
      case "field":
        if (FIELD_REF.current) FIELD_REF.current.value = "";
        break;
      case "sort":
        if (SORT_REF.current) {
          SORT_REF.current.value = "";
          onSort("");
        }
        break;
    }
  }

  function HANDLE_SEARCH_TERM_CHANGE(event: ChangeEvent<HTMLInputElement>): void {
    SET_SEARCH_TERM(event.target.value);
  }

  function HANDLE_FILTER_TOGGLE(): void {
    SET_IS_FILTER_OPEN(!IS_FILTER_OPEN);
  }

  function HANDLE_FILTER_CHANGE(
    event: ChangeEvent<HTMLSelectElement>,
    filterId: string,
    label: string,
  ): void {
    UPDATE_FILTER({
      id: filterId,
      label: label,
      value: event.target.value,
    });
  }

  function HANDLE_SORT_CHANGE(event: ChangeEvent<HTMLSelectElement>): void {
    const VALUE: string = event.target.value;
    if (VALUE === "") {
      onSort("");
      REMOVE_FILTER("sort");
      return;
    }
    if (VALUE === "A-Z" || VALUE === "Z-A") {
      onSort(VALUE === "A-Z" ? "asc" : "desc");
    }
    HANDLE_FILTER_CHANGE(event, "sort", "Sortierung");
  }

  return (
    <div className="w-full space-y-4">
      <div className="flex flex-wrap gap-4">
        <div className="flex-1 min-w-[300px] flex gap-2">
          <div className="relative flex-1">
            <input
              type="text"
              value={SEARCH_TERM}
              onChange={HANDLE_SEARCH_TERM_CHANGE}
              placeholder="Stichwortsuche..."
              className="w-full p-3 pl-4 border-2 border-hellblau rounded-xl 
                       focus:outline-none focus:border-dunkelblau focus:ring-2 
                       focus:ring-dunkelblau/20 transition-all"
            />
          </div>
          <button
            className="px-6 bg-dunkelblau text-white rounded-xl hover:bg-opacity-90 
                           transition-colors duration-200 flex items-center gap-2"
          >
            <MagnifyingGlassIcon className="h-5 w-5" />
            <span>Suchen</span>
          </button>
        </div>

        <button
          onClick={HANDLE_FILTER_TOGGLE}
          className={`flex items-center gap-2 px-4 py-3 rounded-xl 
                    transition-colors duration-200 ${
                      IS_FILTER_OPEN
                        ? "bg-orange text-white hover:bg-orange/90"
                        : "bg-dunkelblau text-white hover:bg-opacity-90"
                    }`}
        >
          <FunnelIcon className="h-5 w-5" />
          <span>Filter {IS_FILTER_OPEN ? "schließen" : "öffnen"}</span>
        </button>
      </div>

      {IS_FILTER_OPEN && (
        <div className="w-full p-4 bg-white rounded-xl shadow-lg border border-gray-200 animate-fadeIn">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
            <div>
              <label className="block mb-2 font-medium text-dunkelblau">Sprache</label>
              <select
                ref={LANGUAGE_REF}
                onChange={function (e: ChangeEvent<HTMLSelectElement>): void {
                  HANDLE_FILTER_CHANGE(e, "language", "Sprache");
                }}
                className="w-full p-2 border rounded-lg focus:outline-none focus:border-dunkelblau"
              >
                <option value="">Auswählen...</option>
                <option value="Deutsch">Deutsch</option>
                <option value="Englisch">Englisch</option>
              </select>
            </div>

            <div>
              <label className="block mb-2 font-medium text-dunkelblau">Entfernung</label>
              <select
                ref={DISTANCE_REF}
                onChange={function (e: ChangeEvent<HTMLSelectElement>): void {
                  HANDLE_FILTER_CHANGE(e, "distance", "Entfernung");
                }}
                className="w-full p-2 border rounded-lg focus:outline-none focus:border-dunkelblau"
              >
                <option value="">Auswählen...</option>
                <option value="10">10 km</option>
                <option value="25">25 km</option>
                <option value="50">50 km</option>
              </select>
            </div>

            <div>
              <label className="block mb-2 font-medium text-dunkelblau">Fachrichtung</label>
              <select
                ref={FIELD_REF}
                onChange={function (e: ChangeEvent<HTMLSelectElement>): void {
                  HANDLE_FILTER_CHANGE(e, "field", "Fachrichtung");
                }}
                className="w-full p-2 border rounded-lg focus:outline-none focus:border-dunkelblau"
              >
                <option value="">Auswählen...</option>
                <option value="IT">IT</option>
                <option value="Wissenschaft">Wissenschaft</option>
                <option value="Ingenieurwesen">Ingenieurwesen</option>
              </select>
            </div>
          </div>

          <hr className="my-4 border-t border-gray-200" />

          <div className="space-y-4">
            <div>
              <label className="block mb-2 font-medium text-dunkelblau">Sortierung</label>
              <select
                ref={SORT_REF}
                onChange={HANDLE_SORT_CHANGE}
                className="w-full p-2 border rounded-lg focus:outline-none focus:border-dunkelblau"
              >
                <option value="">Auswählen...</option>
                <option value="A-Z">A-Z</option>
                <option value="Z-A">Z-A</option>
                <option value="Relevanz">Nach Relevanz</option>
              </select>
            </div>
          </div>
        </div>
      )}

      {ACTIVE_FILTERS.length > 0 && (
        <div className="w-full p-3 bg-hellblau/5 rounded-xl border border-hellblau/20">
          <div className="flex items-center gap-2 mb-2">
            <FunnelIcon className="h-4 w-4 text-dunkelblau/60" />
            <span className="text-sm text-dunkelblau/80 font-medium">Aktive Filter</span>
          </div>

          <div className="flex flex-wrap gap-2">
            {ACTIVE_FILTERS.map(function (filter: IFilter): ReactElement {
              return (
                <span
                  key={filter.id}
                  className="group inline-flex items-center gap-2 px-3 py-1.5 
                           bg-white hover:bg-hellblau/5 
                           border border-hellblau/30 hover:border-hellblau
                           text-dunkelblau rounded-lg text-sm
                           transition-all duration-200 shadow-sm"
                >
                  <span className="font-medium opacity-60 text-xs">{filter.label}:</span>
                  <span>{filter.value}</span>
                  <button
                    onClick={function (): void {
                      REMOVE_FILTER(filter.id);
                    }}
                    className="opacity-60 hover:opacity-100 hover:text-orange 
                             transition-all duration-200 rounded-full
                             hover:bg-orange/10 p-1 -mr-1"
                  >
                    <XMarkIcon className="h-3.5 w-3.5" />
                  </button>
                </span>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default SearchBar;
